<template>
  <!-- <v-container fluid> -->
  <v-card
    flat
    class="custom-grey-border remove-border-radius"
    :disabled="pageLoading"
  >
    <v-card-title
      class="headline grey lighten-4 px-0"
      @click="show_terms_condition = !show_terms_condition"
    >
      <div class="px-3">
        <v-icon>{{
          !show_terms_condition ? "mdi-chevron-down" : "mdi-chevron-up"
        }}</v-icon>
      </div>
      <h3 class="font-weight-700 custom-headline color-custom-blue">
        Terms &amp; Conditions
      </h3>
    </v-card-title>
    <v-expand-transition>
      <v-card-text class="font-size-16" v-show="show_terms_condition">
        <editor v-model="termsConditions" />
      </v-card-text>
    </v-expand-transition>
  </v-card>
  <!-- </v-container> -->
</template>

<script>
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "terms-conditions",
  data() {
    return {
      show_terms_condition: false,
      timeoutLimit: 500,
      timeout: null,
      termsConditions: null,
    };
  },
  components: {
    editor: TinyMCE,
  },
  props: {
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    updateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    termsConditions() {
      const _this = this;
      clearTimeout(_this.timeout);
      _this.timeout = setTimeout(function () {
        TermConditionEventBus.$emit(
          "update:term-condition",
          _this.termsConditions
        );
      }, _this.timeoutLimit);
    },
    updateData: {
      deep: true,
      handler() {
        this.termsConditions = this.updateData.term_conditions;
      },
    },
  },
};
</script>
